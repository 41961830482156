<template>
  <div class="page-container">
    <div class="container-wide" align="center">
      <b-row align-v="center" class="row pt-3 pb-3">
        <div class="col-md-auto">
          <b-form-group
            :label="$t('from')"
            label-for="from"
            label-cols="5"
            content-cols="1"
            label-align="right"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <datetime
              id="from"
              v-model="fromDate"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-md-auto">
          <b-form-group
            :label="$t('untilAndIncluding')"
            label-for="to"
            label-cols="5"
            content-cols="1"
            label-align="right"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <datetime
              id="to"
              v-model="toDate"
              title="To"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-md-auto">
          <b-form-group
            :label="$t('search')"
            label-for="search"
            label-cols="4"
            content-cols="0"
            label-align="right"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="search"
              v-model="search"
              :placeholder="$t('typeToSearch')"
              debounce="500"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-auto">
          <b-form-group class="m-2" align="left">
            <b-checkbox
              id="showCanceled"
              v-model="showCanceled"
              size="sm"
              class="text-sm"
              name="showCanceled"
              >{{ $t('showCanceled') }}
            </b-checkbox>
          </b-form-group>
        </div>
        <div class="col-md-auto">
          <b-form-group class="mt-3" align-v="center">
            <b-form-radio-group v-model="allBuildings" size="sm">
              <b-form-radio value="false">{{
                $t('thisBuilding')
              }}</b-form-radio>
              <b-form-radio value="true">{{
                $t('allBuildings')
              }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-auto">
          <b-button
            v-if="reservations.length"
            @click="exportCsvData"
            >{{ $t('export') }}</b-button
          >
        </div>
      </b-row>
      <div>
        <b-table
          striped
          hover
          :items="reservations"
          :fields="fields"
          show-empty
        >
          <template #cell(org_path)="data">
            {{ data.value.substring(1) }}
          </template>
          <template #cell(start_datetime)="data">
            {{ formatDate(data.value) }}
          </template>
          <template #cell(end_datetime)="data">
            {{ formatTime(data.value) }}
          </template>
          <template #cell(checkInDatetime)="data">
            {{ formatTime(data.value) }}
          </template>
          <template #cell(cancelled_at)="data">
            {{ formatDate(data.value) }}
          </template>
          <template #cell(created_at)="data">
            {{ formatDate(data.value) }}
          </template>
          <template #cell(cancelled_by)="data">
            {{ $t(data.value) }}
          </template>
          <template #cell(reservation_subject)="data">
            {{ $t(data.value) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import moment from 'moment-timezone'
import OkkuApi from '@/services/OkkuApi'
import { exportAsCSVMixin } from '@/mixins/mixins'

export default {
  name: 'ReservationDetails',
  components: {
    datetime: Datetime
  },
  mixins: [exportAsCSVMixin],
  data() {
    return {
      reservations: [],
      fromDate: moment()
        .startOf('month')
        .add(1, 'days')
        .toISOString(),
      toDate: moment()
        .endOf('month')
        .toISOString(),
      search: undefined,
      allBuildings: false,
      showCanceled: true,
      fields: [
        {
          key: 'org_path',
          label: this.$t('building'),
          sortable: true
        },
        {
          key: 'object_name',
          label: this.$t('spot'),
          sortable: true
        },
        {
          key: 'lastName',
          sortable: true,
          label: this.$t('lastName')
        },
        {
          key: 'firstName',
          sortable: false,
          label: this.$t('firstName')
        },
        {
          key: 'employeeNumber',
          label: this.$t('employeeNumber'),
          sortable: true
        },
        {
          key: 'reserved_for_user',
          label: this.$t('email')
        },
        {
          key: 'start_datetime',
          label: this.$t('startTime'),
          sortable: true
        },
        {
          key: 'end_datetime',
          label: this.$t('endTime'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('createdAt'),
          sortable: true
        },
        {
          key: 'cancelled_at',
          label: this.$t('cancelledAt'),
          sortable: true
        },
        {
          key: 'cancelledBy',
          label: this.$t('cancelledBy'),
          sortable: true
        },
        {
          key: 'checkInDatetime',
          label: this.$t('checkedIn'),
          sortable: true
        },
        {
          key: 'userpool',
          label: this.$t('userpool'),
          sortable: true
        },
        {
          key: 'reservation_subject',
          label: this.$t('subject'),
          sortable: true
        }
      ]
    }
  },
  watch: {
    fromDate() {
      this.getReservationDetails()
    },
    toDate() {
      this.getReservationDetails()
    },
    search() {
      this.getReservationDetails()
    },
    allBuildings() {
      this.getReservationDetails()
    },
    showCanceled() {
      this.getReservationDetails()
    }
  },
  mounted() {
    this.getReservationDetails()
  },
  methods: {
    exportCsvData() {
      const reservations = this.reservations.map(reservation => {
        // Rename columns for better readability
        reservation.building = reservation.org_path
        reservation.spot = reservation.object_name

        // delete extra columns
        delete reservation.org_path
        delete reservation.context
        delete reservation.object_name

        return reservation
      })

      this.csvExport(reservations, 'reservation-details', [
        'reservation_subject'
      ])
    },
    home(event) {
      window.location.href = '/workplace'
    },
    async getReservationDetails() {
      let reservations = await OkkuApi.getReservationDetails(
        this.fromDate,
        this.toDate,
        this.search,
        this.allBuildings === 'true'
      )

      // If false, we filter out all canceled reservations.
      if (!this.showCanceled) {
        reservations = reservations.filter(
          reservation => reservation.cancelled_at === null
        )
      }

      reservations.forEach(reservation => {
        if (!reservation.cancelledBy) reservation.cancelledBy = null
        if (!reservation.checkInDatetime)
          reservation.checkInDatetime = null
      })

      this.reservations = reservations
    },
    formatDate(date) {
      if (date) {
        return moment(String(date)).format('ddd DD-MMM HH:mm')
      }
      return date
    },
    formatTime(date) {
      if (date) {
        return moment(String(date)).format('HH:mm')
      }
      return date
    }
  }
}
</script>

<style lang="scss" scoped>
.b-table {
  font-size: smaller;
}
</style>
